<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="mb-4">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">{{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-on:submit="submitForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>
    <WeCard>
      <!-- Title -->
      <WeInput
        name="title"
        v-model="form.title"
        label="Başlık"
        v-bind:error="$v.form.title.$error"
      />
      <!-- ./Title -->

      <!-- File Upload -->
      <WeFileUploader v-model="form.file" meta-visible="" sizeLimit="20480" />
      <!-- ./File Upload -->
    </WeCard>

    <!-- List Members -->
    <WeCard v-if="memberList.length" class="mt-4">
      <h5>Üye Listesi</h5>
      <hr />
      <WeTable
        v-bind:index="false"
        v-bind:data="memberList"
        v-bind:allowSortIndex="false"
        v-bind:columns="memberTableColumns"
      ></WeTable>
    </WeCard>
    <!-- ./List Members -->
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      form: {
        title: null,
        file: {
          files: [],
          fileData: null,
        },
      },
      memberList: [],
      memberTableColumns: [
        { name: "first_name", th: "Ad", type: "string" },
        { name: "last_name", th: "Soyad", type: "string" },
        { name: "email", th: "E-Posta Adresi", type: "string" },
        {
          name: "notification_email",
          th: "Abonelik Durumu",
          type: "boolean_disabled",
        },
      ],
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("emailList", ["create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    getEmailListById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form.title = result?.title;
            this.memberList = result?.members;
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    addEmailList() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateEmailList() {
      this.update({
        id: this.$route.params.id,
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.updateEmailList();
      } else {
        this.addEmailList();
      }
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "E-Posta Listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    redirectToList() {
      this.$router.push("/email-lists");
    },
  },
  computed: {
    ...mapState({
      shared: (state) => state.shared,
      emailList: (state) => state.emailList,
    }),
    getTitle() {
      return this.$route.params.id
        ? "E-POSTA LİSTESİ DÜZENLE"
        : "E-POSTA LİSTESİ EKLE";
    },
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
  },
  mounted() {
    this.getEmailListById();
  },
};
</script>